@import (reference) './imports/global';

.aui-close-button {
    #aui.icon(@aui-glyph-close,{
        position: static;
    });
    background-color: transparent;
    -webkit-appearance: none;
    border: 0;
    border-radius: @aui-border-radius-smallish;
    cursor: pointer;
    // AUI-5192: currentColor is quoted to work around Edge 18 bug.
    color: var(--aui-close-button-icon-color, 'currentcolor');
    height: @aui-close-button-size;
    width: @aui-close-button-size;
    line-height: 100%;
    margin-left: 5px;
    padding: 0;

    #aui.focus(#aui.with-focus-ring());
}
