@import (reference) './imports/global';

.aui-banner {
    #aui-message.icon-bar(var(--aui-message-ig), var(--aui-message-icolor));
    &::after {
        left: 15px;
    }

    padding: var(--aui-message-padding);
    padding-left: var(--aui-message-gutter);
    position: relative;

    a:not(.aui-button),
    .aui-button-link {
        text-decoration: underline;
    }

    &.aui-banner-error {
        --aui-message-ig: @aui-glyph-exclamation-circle;
        background-color: var(--aui-banner-error-bg-color);
        color: var(--aui-banner-error-text-color);
    }
}

// Interop for banners and messages in header.
#header .aui-banner,
#header .aui-message {
    --aui-message-padding: 10px;
    --aui-message-gutter: 40px;
    --aui-message-isize: @aui-icon-size-small;
    // AUI-5192: currentColor is quoted to work around Edge 18 bug.
    --aui-focus: 'currentcolor';
    --aui-link-color: 'currentcolor';
    --aui-link-hover-color: 'currentcolor';
    --aui-link-active-color: 'currentcolor';
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
}
