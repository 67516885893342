@import (reference) './imports/global';
@import (reference) './imports/aui-theme/core/layers';

aui-header,
.aui-header,
.aui-header-inner,
.aui-header-before,
.aui-header-primary,
.aui-header-secondary {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.aui-header-secondary {
    justify-content: flex-end;
}

.aui-nav-images() {
    img, svg {
        max-height: calc(var(--aui-appheader-logo-height, var(--aui-appheader-item-height, 100%)) - 10px);
        display: inline-block;
        vertical-align: text-bottom;
    }
}

// Structural styles
.aui-header {
    --aui-badge-text-color: var(--aui-appheader-text-color);
    --aui-badge-bg-color: var(--aui-appheader-item-focus-bg-color);
    --aui-appheader-height: auto;
    --aui-appheader-item-height: 40px;
    --aui-appheader-item-border-radius: 0px;
    @section-gap: 20px;

    box-sizing: border-box;
    padding: 0 @aui-grid;
    position: relative;

    .aui-header-before {
        flex-grow: 0;
        margin-right: @section-gap;
    }

    // Container for the product's name
    .aui-header-logo, .aui-header-logo a {
        display: flex;
        align-items: center;
        justify-items: flex-start;
    }

    .aui-header-logo {
        margin: 0 @section-gap 0 0;
        padding: 0;
        height: var(--aui-appheader-logo-height, var(--aui-appheader-item-height));
        line-height: 1;
        text-wrap: none;
        vertical-align: text-bottom;
        white-space: nowrap;

        // Set styles for img/svg inside the header logo
        .aui-nav-images();

        // Variant where logo is a CSS background-image
        .aui-header-logo-device {
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: contain;
            box-sizing: content-box;
            display: inline-block;
            text-indent: -9999px;
            text-align: left;

            + .aui-header-logo-text {
                margin-left: .5em;
            }
        }
        &.aui-header-logo-textonly .aui-header-logo-device {
            text-indent: 0;
        }
    }

    // Navigational items
    .aui-header-primary,
    .aui-header-secondary {
        > .aui-nav {
            align-items: center;
            display: flex;
            justify-content: space-around;
        }

        > .aui-nav > li {
            > a,
            > button {
                white-space: nowrap;
                display: inline-block;

                // Set styles for avatar images inside nav items
                > .aui-avatar {
                    .aui-nav-images();
                }
            }
        }
    }

    // Aesthetic styles.
    & {
        // IMPORTANT! Don't set text color here, otherwise it will bleed in to layered elements
        // like modals and dropdowns, which would cause them to render funnily.
        background: var(--aui-appheader-bg-color);
        height: var(--aui-appheader-height, initial);

        // Set color in locations where it will not bleed in to nested layered components.
        .aui-header-before > :not(.aui-layer),
        .aui-header-logo {
            color: var(--aui-appheader-text-color, var(--aui-body-text));
        }

        // Target the links in the app header.
        //   We need to use the direct child combinator to avoid applying styles to
        //   dropdowns or other layers nested in the header.
        //   We also avoid styling buttons, which might be placed inside nav lists for some reason.
        .aui-header-primary > .aui-nav > li > a:not(.aui-button),
        .aui-header-secondary > .aui-nav > li > a:not(.aui-button),
        .aui-header-logo > a {

            & {
                display: flex;
                align-items: center;
                border-radius: var(--aui-appheader-item-border-radius);
                box-sizing: border-box;
                height: var(--aui-appheader-item-height, auto);
                padding: var(--aui-appheader-item-padding-y, 5px) var(--aui-appheader-item-padding-x, 10px);
                background-color: var(--aui-appheader-item-bg-color, transparent);
                color: var(--aui-appheader-text-color, var(--aui-body-text));
                text-decoration: none;
            }

            &:hover,
            &:focus {
                background-color: var(--aui-appheader-item-focus-bg-color);
                color: var(--aui-appheader-item-focus-text-color, inherit);
            }

            #aui.focus(#aui.with-focus-ring(@inset: true));

            &:active,
            &.active {
                background-color: var(--aui-appheader-item-active-bg-color);
                color: var(--aui-appheader-item-active-text-color);
            }
        }

        // Special-case padding to separate non-nav items from nav items.
        .aui-header-primary > .aui-nav > li > .aui-button,
        .aui-header-primary > .aui-nav > li > .aui-buttons {
            margin-left: @section-gap / 2;
        }

        .aui-header-secondary > .aui-nav > li > .aui-quicksearch {
            margin-right: @section-gap / 2;
        }

        // Override the icon styles so that every icon in the header is a uniform size...
        // ...but only in the right-hand side of the header.
        .aui-header-secondary > .aui-nav > li > a > .aui-icon {
            --aui-icon-size: 24px;
        }

        // Bump up the font size when text is used in place of a logo
        .aui-header-logo {
            font-size: @aui-font-size-xxlarge;
        }

        // Reduce the font size when a logo has supporting text
        .aui-header-logo .aui-header-logo-text {
            font-size: @aui-font-size-medium;
        }
    }
}
