@import (reference) '../theme';

@aui-nav-heading-text-color: var(--aui-itemheading-text);
@aui-nav-heading-text-font-size: @aui-font-size-xsmall;

@aui-nav-link-border-radius: @aui-border-radius-small;

// These values cannot change until a major version. See the item-base for an explanation.
@aui-nav-link-effective-height: 30px;
@aui-nav-link-spacing-horizontal: 10px;
@aui-nav-link-spacing-vertical: 7px;

// ### Nav sub-tree

@aui-nav-group-divider-color: var(--aui-border);
@aui-nav-subtree-line-color: var(--aui-border);
@aui-nav-subtree-toggle-icon-color: var(--aui-nav-text-color);
@aui-nav-subtree-toggle-icon-size: 20px;

@aui-nav-subtree-indicator-width: 1px;

// ### Actions lists

@aui-nav-actions-list-margin-top: @aui-grid;
@aui-nav-actions-list-item-margin-bottom: (@aui-grid / 2);
@aui-nav-actions-list-item-font-size: @aui-font-size-medium;

@aui-nav-actions-list-divider-color: var(--aui-border-strong);
@aui-nav-actions-list-divider-size: 2px;
@aui-nav-actions-list-divider-spacing: 10px;

// ### Paginated

@aui-nav-pagination-text-color: var(--aui-nav-pagination-text-color);
@aui-nav-pagination-active-text-color: var(--aui-nav-pagination-active-text-color);


#aui-nav {
    // See also: #aui-dropdowns.aui-dropdown-option-base();
    .item-base(@height: @aui-nav-link-effective-height) {
        border-radius: var(--aui-item-border-radius, @aui-nav-link-border-radius);
        box-sizing: content-box;
        // This *cannot* be changed to flexbox until a major version.
        // Plain-text often ends up in these items alongside elements that are currently floated;
        // it wouldn't be possible to re-arrange them correctly using flexbox.
        display: block;
        // the line-height + padding adds up to an effective 30px height for the element,
        // but the inner content box is only 16px tall. This enables things like
        // lozenges and small icons to line up correctly with the text, without explicit treatment.
        // this is assumed and exploited in places like the sidebar's navigation.
        line-height: unit((@height - (2 * @aui-nav-link-spacing-vertical)) / @aui-nav-actions-list-item-font-size);
        padding: @aui-nav-link-spacing-vertical @aui-nav-link-spacing-horizontal;
    }

    .item-base-inner-link() {
        color: inherit;
        text-decoration: inherit;
        margin: -@aui-nav-link-spacing-vertical -@aui-nav-link-spacing-horizontal;
    }

    .item-style(normal; @not-actionable:none) {
        background-color: var(--aui-item-bg, transparent);
        color: var(--aui-item-text, var(--aui-body-text));
        text-decoration: none;

        & when not (@not-actionable) {
            cursor: pointer;
        }
    }

    .item-style(hover) {
        background-color: var(--aui-item-focus-bg);
        color: var(--aui-item-focus-text, inherit);
    }

    .item-style(active) {
        background-color: var(--aui-item-active-bg);
        color: var(--aui-item-active-text);
    }

    .item-style(selected) {
        background-color: var(--aui-item-selected-bg);
        color: var(--aui-item-selected-text);
        font-weight: @aui-font-weight-medium;
    }

    .item-style(disabled) {
        background-color: var(--aui-item-disabled-bg, transparent);
        color: var(--aui-item-disabled-text, var(--aui-lesser-body-text));
        cursor: not-allowed;
        text-decoration: none;
    }

}
