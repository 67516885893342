@import (reference) './media-queries';

#aui {
    .shadow {
        // For use on cards and other things that sit flush on top of other layers.
        .z100() {
            box-shadow:
                0 1px 1px var(--aui-shadow1),
                0 0 1px 0 var(--aui-shadow2);
        }

        // For inline dialogs
        .z200() {
            .z-base(0 4px 8px -2px var(--aui-shadow2));
        }

        // For modal dialogs
        .z300() {
            .z-base(0 8px 16px -4px var(--aui-shadow2));
        }

        // For flags and notifications
        .z500() {
            .z-base(0 20px 32px -8px var(--aui-shadow2));
        }

        // Internal use only
        .z-base(@shadow) {
            box-shadow: @shadow, 0 0 1px var(--aui-shadow2);
            // Improve legibility of the element's "border" in Legacy Edge.
            .legacy-edge({
                box-shadow: @shadow,
                    0 0 1px var(--aui-shadow1),
                    1px 0 1px var(--aui-shadow1),
                    -1px 0 1px var(--aui-shadow1),
                    0 -1px 1px var(--aui-shadow1);
            });
        }
    }
}
