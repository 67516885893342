@import (reference) 'core-variables';
@import (reference) 'component-variables';

#aui-themes {
    .when-light(@rules) {
        @rules();
    }

    .when-dark(@rules) {
        body.aui-theme-dark & {
            @rules();
        }
    }

    .switch-light-dark(@lightRules; @darkRules) {
        #aui-themes.when-light(@lightRules);
        #aui-themes.when-dark(@darkRules);
    }
}
