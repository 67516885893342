#aui-themes {
    .light() {
        @import (reference) '../adg/adg-colors';
        @import (reference) '../adg/adg-neutral-light';

        // Actual theme
        --aui-shadow1: @ak-color-N40A;
        --aui-shadow2: @ak-color-N50A;
        --aui-blanket: rgba(@ak-color-N800, 0.45);

        // Page
        --aui-body-text: @ak-color-N800;
        --aui-lesser-body-text: @ak-color-N300;
        --aui-lesser-header-text: @ak-color-N200;
        --aui-body-background: @ak-color-N20;
        --aui-page-background: @ak-color-N0;
        --aui-page-border: @ak-color-N40;
        --aui-border: @ak-color-N40;
        --aui-border-strong: @ak-color-N100;
        --aui-focus: @ak-color-B100;
        --aui-link-color: @ak-color-B400;
        --aui-link-decoration: none;
        --aui-link-hover-color: @ak-color-B300;
        --aui-link-active-color: @ak-color-B500;
        --aui-link-hover-decoration: underline;
        --aui-link-visited-color: @ak-color-P500;

        // "Item" pattern - used for navigation, buttons, dropdown options, pagination, and similar.
        --aui-itemheading-text: @ak-color-N300;
        --aui-item-bg: transparent;
        --aui-item-text: @ak-color-N500;
        --aui-item-focus-bg: @ak-color-N30A;
        --aui-item-focus-text: @ak-color-N500;
        --aui-item-active-text: @ak-color-B400;
        --aui-item-active-bg: @ak-color-B50;
        --aui-item-selected-bg: var(--aui-item-focus-bg);
        --aui-item-selected-text: var(--aui-item-focus-text);
        --aui-item-disabled-bg: transparent;
        --aui-item-disabled-text: @ak-color-N100;

        // Tooltips
        --aui-tooltip-bg-color: @ak-color-N800;
        --aui-tooltip-border-color: @ak-color-N800;
        --aui-tooltip-content-text-color: @ak-color-N0;
        --aui-tooltip-title-text-color: @ak-color-N0;

        // Help text and onboarding messaging
        --aui-help-color: @ak-color-P400;

        // Badges
        --aui-badge-bg-color: @ak-color-N40A;
        --aui-badge-text-color: @ak-color-N800;
        --aui-badge-primary-bg-color: @ak-color-B400;
        --aui-badge-primary-text-color: @ak-color-N0;
        --aui-badge-added-bg-color: @ak-color-G50;
        --aui-badge-addded-text-color: @ak-color-G500;
        --aui-badge-removed-bg-color: @ak-color-R50;
        --aui-badge-removed-text-color: @ak-color-R500;
        --aui-badge-important-bg-color: @ak-color-R400;
        --aui-badge-important-text-color: @ak-color-N0;
        --aui-badge-on-blue-text-color: @ak-color-N0;
        --aui-badge-on-blue-bg-color: rgba(@ak-color-N0, 0.25);

        // Lozenges
        --aui-lozenge-bg-color: @ak-color-N500;
        --aui-lozenge-text-color: @ak-color-N0;
        --aui-lozenge-subtle-bg-color: @ak-color-N40;
        --aui-lozenge-subtle-text-color: @ak-color-N500;
        --aui-lozenge-success-bg-color: @ak-color-G400;
        --aui-lozenge-success-text-color: @ak-color-N0;
        --aui-lozenge-success-subtle-bg-color: @ak-color-G50;
        --aui-lozenge-success-subtle-text-color: @ak-color-G500;
        --aui-lozenge-current-bg-color: @ak-color-B400;
        --aui-lozenge-current-text-color: @ak-color-N0;
        --aui-lozenge-current-subtle-bg-color: @ak-color-B50;
        --aui-lozenge-current-subtle-text-color: @ak-color-B500;
        --aui-lozenge-moved-bg-color: @ak-color-Y400;
        --aui-lozenge-moved-text-color: @ak-color-N800;
        --aui-lozenge-moved-subtle-bg-color: @ak-color-Y75;
        --aui-lozenge-moved-subtle-text-color: @ak-color-N800;
        --aui-lozenge-error-bg-color: @ak-color-R500;
        --aui-lozenge-error-text-color: @ak-color-N0;
        --aui-lozenge-error-subtle-bg-color: @ak-color-R50;
        --aui-lozenge-error-subtle-text-color: @ak-color-R500;
        --aui-lozenge-new-bg-color: @ak-color-P400;
        --aui-lozenge-new-text-color: @ak-color-N0;
        --aui-lozenge-new-subtle-bg-color: @ak-color-P50;
        --aui-lozenge-new-subtle-text-color: @ak-color-P500;

        // Messages
        --aui-message-info-bg-color: @ak-color-B50;
        --aui-message-info-icon-color: @ak-color-B400;
        --aui-message-info-text-color: @ak-color-N600;
        --aui-message-success-bg-color: @ak-color-G50;
        --aui-message-success-icon-color: @ak-color-G400;
        --aui-message-success-text-color: @ak-color-N600;
        --aui-message-warning-bg-color: @ak-color-Y50;
        --aui-message-warning-icon-color: @ak-color-Y400;
        --aui-message-warning-text-color: @ak-color-N600;
        --aui-message-error-bg-color: @ak-color-R50;
        --aui-message-error-icon-color: @ak-color-R400;
        --aui-message-error-text-color: @ak-color-N600;
        --aui-message-change-bg-color: @ak-color-P50;
        --aui-message-change-icon-color: @ak-color-P400;
        --aui-message-change-text-color: @ak-color-N600;

        // Banners
        --aui-banner-error-bg-color: @ak-color-R400;
        --aui-banner-error-text-color: @ak-color-N0;

        // Flags
        --aui-flag-bg-color: var(--aui-dropdown-bg-color); // see also: inline dialogs
        --aui-flag-info-color: @ak-color-B400;
        --aui-flag-success-color: @ak-color-G300;
        --aui-flag-warning-color: @ak-color-Y300;
        --aui-flag-error-color: @ak-color-R300;

        // Buttons
        --aui-button-default-bg-color: @ak-color-N30A;
        --aui-button-default-text-color: @ak-color-N600;
        --aui-button-default-hover-bg-color: @ak-color-N40A;
        --aui-button-default-active-bg-color: @ak-color-B50;
        --aui-button-default-active-text-color: @ak-color-B400;
        --aui-button-default-selected-bg-color: @ak-color-N600;
        --aui-button-default-selected-text-color: @ak-color-N0;
        --aui-button-default-disabled-bg-color: @ak-color-N20A;
        --aui-button-default-disabled-text-color: @ak-color-N70;
        --aui-button-primary-bg-color: @ak-color-B400;
        --aui-button-primary-text-color: @ak-color-N0;
        --aui-button-primary-hover-bg-color: @ak-color-B300;
        --aui-button-primary-active-bg-color: @ak-color-B400;
        --aui-button-primary-active-text-color: @ak-color-N0;
        --aui-button-primary-disabled-bg-color: var(--aui-button-default-disabled-bg-color);
        --aui-button-primary-disabled-text-color: var(--aui-button-default-disabled-text-color);
        --aui-button-light-bg-color: @ak-color-N0;
        --aui-button-subtle-text-color: @ak-color-N600;

        // "Labels" (e.g., page tags, selected values in components, etc.)
        --aui-label-text-color: inherit;
        --aui-label-link-color: inherit;
        --aui-label-bg-color: @ak-color-N20;
        --aui-label-hover-bg-color: @ak-color-N20;
        --aui-label-close-hover-bg-color: @ak-color-R75;
        --aui-label-close-hover-text-color: @ak-color-N800;

        // Forms
        --aui-form-placeholder-text-color: @ak-color-N100;
        --aui-form-placeholder-disabled-text-color: @ak-color-N70;
        --aui-form-label-text-color: @ak-color-N200;
        --aui-form-error-text-color: @ak-color-R400;
        --aui-form-description-text-color: @ak-color-N200;
        --aui-form-disabled-field-bg-color: @ak-color-N20;
        --aui-form-disabled-field-text-color: @ak-color-N80;
        --aui-form-disabled-field-label-color: @ak-color-N80;
        --aui-form-field-border-color: @ak-color-N40;
        --aui-form-field-hover-border-color: @ak-color-N40;
        --aui-form-field-default-text-color: @ak-color-N800;
        --aui-form-field-default-bg-color: @ak-color-N10;
        --aui-form-field-hover-text-color: var(--aui-form-field-default-text-color);
        --aui-form-field-hover-bg-color: @ak-color-N30;
        --aui-form-field-focus-bg-color: @ak-color-N0;
        --aui-form-select-bg-color: @ak-color-N30;
        --aui-form-select-border-color: @ak-color-N30;
        --aui-form-select-hover-bg-color: @ak-color-N40;
        --aui-form-checkbox-radio-hover-bg-color: @ak-color-B300;
        --aui-form-checkbox-radio-active-bg-color: @ak-color-B50;
        --aui-form-optgroup-text-color: @ak-color-N100;
        --aui-form-optgroup-bg-color: @ak-color-N20;
        --aui-form-option-bg-color: @ak-color-N0;
        --aui-form-pre-bg-color: @ak-color-N20;

        --aui-form-field-autofilled-bg-color: @ak-color-P50;
        --aui-form-field-autofilled-border-color: @ak-color-P300;
        --aui-form-field-autofilled-text-color: @ak-color-P500;

        --aui-form-glyph-disabled-icon-color: @ak-color-N70;
        --aui-form-glyph-disabled-fill-color: @ak-color-N20A;
        --aui-form-glyph-icon-color: @ak-color-N0;
        --aui-form-glyph-fill-color: @ak-color-B400;

        --aui-form-checkbox-active-bg-color: @ak-color-B50;
        --aui-form-checkbox-active-border-color: @ak-color-B50;
        --aui-form-checkbox-active-icon-color: @ak-color-B400;
        --aui-form-radio-unchecked-bg-color: @ak-color-N10;
        --aui-form-radio-unchecked-border-color: @ak-color-N40;
        --aui-form-radio-unchecked-hover-bg-color: @ak-color-N30;
        --aui-form-radio-unchecked-hover-border-color: @ak-color-N40;

        --aui-toggle-default-bg-color: @ak-color-N200;
        --aui-toggle-default-bg-hover-color: @ak-color-N70;
        --aui-toggle-button-color: @ak-color-N0;
        --aui-toggle-on-color: @ak-color-G400;
        --aui-toggle-on-hover-color: @ak-color-G300;
        --aui-toggle-disabled-overlay-color: rgba(@ak-color-N0, 0.5);
        --aui-toggle-tick-color: @ak-color-N0;
        --aui-toggle-cross-color: @ak-color-N0;

        --aui-form-notification-info-color: @ak-color-N80;
        --aui-form-notification-error-color: var(--aui-message-error-icon-color);
        --aui-form-notification-success-color: var(--aui-message-success-icon-color);

        // Progress bars and spinners
        --aui-progressbar-color: @ak-color-N500;
        --aui-progressbar-track-color: @ak-color-N40A;
        --aui-spinner-color: @ak-color-N500;

        // Navigation
        --aui-nav-pagination-text-color: @ak-color-N800; // --aui-body-text
        --aui-nav-pagination-active-text-color: @ak-color-N80;

        // Application header
        --aui-appheader-bg-color: @ak-color-B500;
        --aui-appheader-text-color: @ak-color-B50;
        --aui-appheader-item-focus-bg-color: @ak-color-N90A;
        --aui-appheader-item-focus-text-color: @ak-color-B50;
        --aui-appheader-item-active-bg-color: @ak-color-N90A;
        --aui-appheader-item-active-text-color: @ak-color-B50;
        --aui-appheader-quicksearch-bg-color: @ak-color-N90A;
        --aui-appheader-quicksearch-border-color: transparent;
        --aui-appheader-quicksearch-text-color: @ak-color-B50;
        --aui-appheader-quicksearch-focus-bg-color: var(--aui-appheader-quicksearch-bg-color);
        --aui-appheader-quicksearch-focus-text-color: @ak-color-B50;

        // Sidebar
        --aui-sidebar-icon-color: @ak-color-N400;
        --aui-sidebar-toggle-icon-color: @ak-color-N400;
        --aui-sidebar-bg-color: @ak-color-N20;
        --aui-sidebar-dropdown-arrow-color: @ak-color-N400;
        --aui-sidebar-tooltip-bg-color: @ak-color-N800A;
        --aui-sidebar-badge-bg-color: @ak-color-N50;
        --aui-sidebar-badge-text-color: inherit;

        // Tabs
        --aui-tabs-tab-border-color: @ak-color-N30; // --aui-border
        --aui-tabs-tab-text-color: @ak-color-N500;
        --aui-tabs-tab-hover-text-color: @ak-color-B100;
        --aui-tabs-tab-active-border-color: @ak-color-B400;
        --aui-tabs-tab-active-text-color: @ak-color-B400;

        // Multi-step wizard
        --aui-progress-tracker-step-border-color: @ak-color-N20;
        --aui-progress-tracker-container-color: @ak-color-N20;
        --aui-progress-tracker-current-step-color: @ak-color-B300;
        --aui-progress-tracker-current-step-text-color: var(--aui-progress-tracker-visited-step-text-color);
        --aui-progress-tracker-visited-step-text-color: @ak-color-N800;
        --aui-progress-tracker-visited-step-hover-text-color: @ak-color-B400;
        --aui-progress-tracker-visited-step-active-text-color: @ak-color-B500;
        --aui-progress-tracker-future-step-color: @ak-color-N70;
        --aui-progress-tracker-future-step-text-color: @ak-color-N300;

        // Tables
        --aui-table-row-bg-color: transparent;
        --aui-table-row-text-color: @ak-color-N800; // --aui-body-text
        --aui-table-header-bg-color: transparent;
        --aui-table-heading-text-color: @ak-color-N200;
        --aui-table-border-color: @ak-color-N40;
        --aui-table-caption-bg-color: @ak-color-N20;
        --aui-table-caption-text-color: @ak-color-N100;
        --aui-table-list-row-hover-color: @ak-color-N30A;
        --aui-table-list-row-subtle-color: @ak-color-N60;
        --aui-table-sortable-hover-bg-color: @ak-color-N30A;
        --aui-table-sortable-active-bg-color: @ak-color-B50;
        --aui-table-sortable-active-border-color: @ak-color-B500;
        --aui-table-sortable-active-text-color: @ak-color-B500;
        --aui-table-sortable-selected-bg-color: transparent;
        --aui-table-sortable-selected-border-color: @ak-color-N100;
        --aui-table-sortable-selected-text-color: @ak-color-N400;

        // Dynamic tables
        --aui-restfultable-row-focused-border-color: @ak-color-N60;
        --aui-restfultable-row-create-border-color: @ak-color-N40;
        --aui-restfultable-row-editable-hover-bg-color: var(--aui-form-field-hover-bg-color);
        --aui-restfultable-error-text-color: var(--aui-form-notification-error-color);
        --aui-restfultable-header-row-bg-color: @ak-color-N0;
        --aui-restfultable-header-row-text-color: @ak-color-N200;
        --aui-restfultable-row-moving-bg-color: @ak-color-N20;
        --aui-restfultable-editable-em-text-color: @ak-color-N80;
        --aui-restfultable-row-active-bg-color: @ak-color-N20;
        --aui-restfultable-row-hover-bg-color: @ak-color-N20;

        // Dropdowns
        --aui-dropdown-bg-color: @ak-color-N0;
        --aui-dropdown-border-color: @ak-color-N40; // to match the lightness of the faux border in the "z200" shadow

        // Selects
        --aui-select2-placeholder-text-color: @ak-color-N100;
        --aui-select2-chosen-bg-color: @ak-color-N0;
        --aui-select2-chosen-text-color: @ak-color-N800;
        --aui-select2-chosen-hover-bg-color: @ak-color-N0;
        --aui-select2-chosen-hover-text-color: @ak-color-N800;
        --aui-select2-active-chosen-bg-color: @ak-color-N40;
        --aui-select2-active-chosen-text-color: @ak-color-N800;
        --aui-select2-field-default-bg-color: var(--aui-form-select-bg-color);
        --aui-select2-field-border-color: var(--aui-form-select-border-color);
        --aui-select2-field-hover-bg-color: var(--aui-form-select-hover-bg-color);
        --aui-select2-drop-bg-color: var(--aui-dropdown-bg-color);

        // Dialogs
        --aui-dialog-bg-color: @ak-color-N0;
        --aui-dialog-border-color: @ak-color-N30;
        --aui-dialog-header-bg-color: var(--aui-dialog-bg-color);
        --aui-dialog-header-warning-bg-color: @ak-color-R400;
        --aui-dialog-header-warning-text-color: @ak-color-N0;
        --aui-dialog-button-hover-border-color: @ak-color-N80;
        --aui-dialog-footer-hint-text-color: @ak-color-N400;
        --aui-inline-dialog-bg-color: var(--aui-dropdown-bg-color); // shared this way because both inline dialogs and dropdowns need to layer well on top of modals
        --aui-inline-dialog-border-color: var(--aui-dropdown-border-color);

        // Date pickers
        --aui-datepicker-panel-bg-color: @ak-color-N0;
        --aui-datepicker-panel-divider-color: @ak-color-N40;
        --aui-datepicker-heading-bg-color: @ak-color-N20;
        --aui-datepicker-heading-text-color: @ak-color-N600;
        --aui-datepicker-heading-weekdays-text-color: @ak-color-N300;
        --aui-datepicker-option-bg-color: transparent;
        --aui-datepicker-option-text-color: @ak-color-B400;
        --aui-datepicker-option-focus-bg-color: @ak-color-N20;
        --aui-datepicker-option-focus-text-color: @ak-color-B400;
        --aui-datepicker-option-selected-bg-color: @ak-color-B400;
        --aui-datepicker-option-selected-text-color: @ak-color-N0;
        --aui-datepicker-option-unselectable-bg-color: @ak-color-N20;
        --aui-datepicker-option-unselectable-text-color: @ak-color-N100;
        --aui-datepicker-disabled-text-color: @ak-color-N100;
        --aui-datepicker-hint-text-color: @ak-color-N400;
    }

    .dark() {
        @import (reference) '../adg/adg-colors';
        @import (reference) '../adg/adg-neutral-dark';

        // Actual theme
        --aui-shadow1: @ak-color-N70A;
        --aui-shadow2: @ak-color-N90A;
        --aui-blanket: @ak-color-N100A;

        // Page
        --aui-body-text: @ak-color-N800;
        --aui-lesser-body-text: @ak-color-N200;
        --aui-lesser-header-text: @ak-color-N200;
        --aui-body-background: @ak-color-N30;
        --aui-page-background: @ak-color-N30;
        --aui-page-border: @ak-color-N30;
        --aui-border: @ak-color-N50;
        --aui-border-strong: @ak-color-N100;
        --aui-focus: @ak-color-B100;
        --aui-link-color: @ak-color-B100;
        --aui-link-hover-color: @ak-color-B75;
        --aui-link-active-color: @ak-color-B200;
        --aui-link-visited-color: @ak-color-P100;

        // "Item" pattern
        --aui-item-bg: transparent;
        --aui-item-text: @ak-color-N600;
        --aui-item-focus-bg: rgba(@ak-color-N200, 0.3);
        --aui-item-focus-text: @ak-color-N600;
        --aui-item-active-bg: @ak-color-B75;
        --aui-item-active-text: @ak-color-B400;
        --aui-item-selected-bg: var(--aui-item-focus-bg);
        --aui-item-selected-text: var(--aui-item-focus-text);
        --aui-item-disabled-bg: transparent;
        --aui-item-disabled-text: @ak-color-N100;

        // Tooltips
        --aui-tooltip-bg-color: @ak-color-N900;
        --aui-tooltip-border-color: @ak-color-N900;
        --aui-tooltip-content-text-color: @ak-color-N40;
        --aui-tooltip-title-text-color: @ak-color-N10;

        // Help text and onboarding messaging
        --aui-help-color: @ak-color-P300;

        // Badges
        --aui-badge-bg-color: @ak-color-N70;
        --aui-badge-text-color: @ak-color-N900;
        --aui-badge-primary-bg-color: @ak-color-B100;
        --aui-badge-primary-text-color: @ak-color-N0;
        --aui-badge-added-bg-color: rgba(0, 102, 68, 0.33);
        --aui-badge-added-text-color: @ak-color-G75;
        --aui-badge-removed-bg-color: rgba(191, 38, 0, 0.33);
        --aui-badge-removed-text-color: @ak-color-R75;
        --aui-badge-important-bg-color: @ak-color-R300;
        --aui-badge-important-text-color: @ak-color-N0;

        // Lozenges
        --aui-lozenge-subtle-bg-color: @ak-color-N50;
        --aui-lozenge-subtle-text-color: @ak-color-N600;
        --aui-lozenge-success-bg-color: @ak-color-G200;
        --aui-lozenge-success-text-color: @ak-color-N0;
        --aui-lozenge-success-subtle-bg-color: rgba(0, 102, 68, 0.33);
        --aui-lozenge-success-subtle-text-color: @ak-color-G75;
        --aui-lozenge-current-bg-color: @ak-color-B200;
        --aui-lozenge-current-text-color: @ak-color-N0;
        --aui-lozenge-current-subtle-bg-color: rgba(7, 71, 166, 0.33);
        --aui-lozenge-current-subtle-text-color: @ak-color-B75;
        --aui-lozenge-moved-bg-color: @ak-color-Y300;
        --aui-lozenge-moved-text-color: @ak-color-N0;
        --aui-lozenge-moved-subtle-bg-color: rgba(255, 139, 0, 0.22);
        --aui-lozenge-moved-subtle-text-color: @ak-color-Y75;
        --aui-lozenge-error-bg-color: @ak-color-R300;
        --aui-lozenge-error-text-color: @ak-color-N0;
        --aui-lozenge-error-subtle-bg-color: rgba(191, 38, 0, 0.33);
        --aui-lozenge-error-subtle-text-color: @ak-color-R75;
        --aui-lozenge-new-bg-color: @ak-color-P200;
        --aui-lozenge-new-text-color: @ak-color-N0;
        --aui-lozenge-new-subtle-bg-color: rgba(64, 50, 148, 0.33);
        --aui-lozenge-new-subtle-text-color: @ak-color-P75;

        // Messages
        --aui-message-info-bg-color: @ak-color-B500;
        --aui-message-info-icon-color: initial;
        --aui-message-info-text-color: @ak-color-N1K;
        --aui-message-success-bg-color: @ak-color-G500;
        --aui-message-success-icon-color: initial;
        --aui-message-success-text-color: @ak-color-N1K;
        --aui-message-warning-bg-color: @ak-color-Y400;
        --aui-message-warning-icon-color: initial;
        --aui-message-warning-text-color: @ak-color-N20;
        --aui-message-error-bg-color: @ak-color-R500;
        --aui-message-error-icon-color: initial;
        --aui-message-error-text-color: @ak-color-N1K;
        --aui-message-change-bg-color: @ak-color-P400;
        --aui-message-change-icon-color: initial;
        --aui-message-change-text-color: @ak-color-N1K;

        // Banners
        --aui-banner-error-bg-color: @ak-color-R400;
        --aui-banner-error-text-color: @ak-color-N2K;

        // Flags
        --aui-flag-bg-color: var(--aui-dropdown-bg-color); // see also: inline dialogs
        --aui-flag-info-color: @ak-color-B200;
        --aui-flag-success-color: @ak-color-G300;
        --aui-flag-warning-color: @ak-color-Y300;
        --aui-flag-error-color: @ak-color-R300;

        // Buttons
        --aui-button-default-bg-color: @ak-color-N60;
        --aui-button-default-hover-bg-color: @ak-color-N90;
        --aui-button-default-text-color: @ak-color-N800;
        --aui-button-default-active-bg-color: @ak-color-B75;
        --aui-button-default-selected-bg-color: @ak-color-N400;
        --aui-button-default-disabled-bg-color: @ak-color-N70;
        --aui-button-default-disabled-text-color: @ak-color-N30;
        --aui-button-primary-bg-color: @ak-color-B100;
        --aui-button-primary-text-color: @ak-color-N30;
        --aui-button-primary-icon-color: @ak-color-N30;
        --aui-button-primary-hover-bg-color: @ak-color-B75;
        --aui-button-primary-active-bg-color: @ak-color-B200;
        --aui-button-primary-active-text-color: @ak-color-N0;
        --aui-button-primary-disabled-bg-color: var(--aui-button-default-disabled-bg-color);
        --aui-button-primary-disabled-text-color: var(--aui-button-default-disabled-text-color);
        --aui-button-light-bg-color: transparent;
        --aui-button-subtle-text-color: @ak-color-N600;

        // "Labels" (e.g., page tags, selected values in components, etc.)
        --aui-label-text-color: @ak-color-N800;
        --aui-label-link-color: @ak-color-N800;
        --aui-label-bg-color: @ak-color-N70;
        --aui-label-hover-bg-color: @ak-color-N80;
        --aui-label-close-hover-bg-color: @ak-color-R400;
        --aui-label-close-hover-text-color: @ak-color-N30;

        // Forms
        --aui-form-placeholder-text-color: @ak-color-N200;
        --aui-form-placeholder-disabled-text-color: @ak-color-N70;
        --aui-form-label-text-color: @ak-color-N400;
        --aui-form-error-text-color: @ak-color-R500;
        --aui-form-description-text-color: @ak-color-N400;
        --aui-form-disabled-field-bg-color: @ak-color-N30;
        --aui-form-disabled-field-text-color: @ak-color-N80;
        --aui-form-disabled-field-label-color: @ak-color-N80;
        --aui-form-field-border-color: @ak-color-N80;
        --aui-form-field-hover-border-color: @ak-color-N80;
        --aui-form-field-default-text-color: @ak-color-N800;
        --aui-form-field-default-bg-color: @ak-color-N40;
        --aui-form-field-hover-text-color: var(--aui-form-field-default-text-color);
        --aui-form-field-hover-bg-color: @ak-color-N30;
        --aui-form-field-focus-bg-color: @ak-color-N0;
        --aui-form-select-bg-color: @ak-color-N70;
        --aui-form-select-border-color: @ak-color-N70;
        --aui-form-select-hover-bg-color: @ak-color-N40;
        --aui-form-checkbox-radio-hover-bg-color: @ak-color-B300;
        --aui-form-checkbox-radio-active-bg-color: @ak-color-B100;
        --aui-form-optgroup-text-color: @ak-color-N100;
        --aui-form-optgroup-bg-color: @ak-color-N20;
        --aui-form-option-bg-color: @ak-color-N0;
        --aui-form-pre-bg-color: @ak-color-N20;

        --aui-form-field-autofilled-bg-color: @ak-color-P500;
        --aui-form-field-autofilled-border-color: @ak-color-P100;
        --aui-form-field-autofilled-text-color: @ak-color-P75;

        --aui-form-glyph-disabled-icon-color: @ak-color-N200;
        --aui-form-glyph-disabled-fill-color: @ak-color-N70;
        --aui-form-glyph-icon-color: @ak-color-N900;
        --aui-form-glyph-fill-color: @ak-color-B400;

        --aui-form-checkbox-active-bg-color: @ak-color-B50;
        --aui-form-checkbox-active-border-color: @ak-color-B50;
        --aui-form-checkbox-active-icon-color: @ak-color-B200;
        --aui-form-radio-unchecked-bg-color: @ak-color-N10;
        --aui-form-radio-unchecked-border-color: @ak-color-N70;
        --aui-form-radio-unchecked-hover-bg-color: @ak-color-N30;
        --aui-form-radio-unchecked-hover-border-color: @ak-color-N70;

        --aui-toggle-default-bg-color: @ak-color-N90;
        --aui-toggle-default-bg-hover-color: @ak-color-N100;
        --aui-toggle-button-color: @ak-color-N30;
        --aui-toggle-on-color: @ak-color-G300;
        --aui-toggle-on-hover-color: @ak-color-G200;
        --aui-toggle-disabled-overlay-color: rgba(@ak-color-N30, 0.5);
        --aui-toggle-tick-color: @ak-color-N30;
        --aui-toggle-cross-color: @ak-color-N30;

        --aui-form-notification-info-color: @ak-color-N80;
        --aui-form-notification-error-color: @ak-color-R300;
        --aui-form-notification-success-color: @ak-color-G300;

        // Progress bars and spinners
        --aui-progressbar-color: @ak-color-N800;
        --aui-progressbar-track-color: @ak-color-N400A;
        --aui-spinner-color: @ak-color-N800;

        // Navigation
        --aui-nav-pagination-text-color: @ak-color-N800; // --aui-body-text
        --aui-nav-pagination-active-text-color: @ak-color-N80;

        // Application header
        --aui-appheader-bg-color: @ak-color-B500;
        --aui-appheader-text-color: @ak-color-B50;
        --aui-appheader-item-focus-bg-color: @ak-color-N50A;
        --aui-appheader-item-focus-text-color: @ak-color-B50;
        --aui-appheader-item-active-bg-color: @ak-color-N90A;
        --aui-appheader-item-active-text-color: @ak-color-B50;
        --aui-appheader-quicksearch-bg-color: @ak-color-N90A;
        --aui-appheader-quicksearch-border-color: transparent;
        --aui-appheader-quicksearch-text-color: @ak-color-B50;
        --aui-appheader-quicksearch-focus-bg-color: var(--aui-appheader-quicksearch-bg-color);
        --aui-appheader-quicksearch-focus-text-color: @ak-color-B50;

        // Sidebar
        --aui-sidebar-icon-color: @ak-color-N400;
        --aui-sidebar-toggle-icon-color: @ak-color-N400;
        --aui-sidebar-bg-color: @ak-color-N20;
        --aui-sidebar-dropdown-arrow-color: @ak-color-N400;
        --aui-sidebar-tooltip-bg-color: @ak-color-N800A;
        --aui-sidebar-badge-bg-color: rgba(@ak-color-N2K, 0.19);
        --aui-sidebar-badge-text-color: inherit;

        // Tabs
        --aui-tabs-tab-border-color: @ak-color-N60;
        --aui-tabs-tab-text-color: @ak-color-N400;
        --aui-tabs-tab-hover-text-color: @ak-color-B100;
        --aui-tabs-tab-active-border-color: @ak-color-B100;
        --aui-tabs-tab-active-text-color: @ak-color-B100;

        // Multi-step wizard
        --aui-progress-tracker-step-border-color: @ak-color-N20;
        --aui-progress-tracker-container-color: @ak-color-N20;
        --aui-progress-tracker-current-step-color: @ak-color-B300;
        --aui-progress-tracker-current-step-text-color: var(--aui-progress-tracker-visited-step-text-color);
        --aui-progress-tracker-visited-step-text-color: @ak-color-N800;
        --aui-progress-tracker-visited-step-hover-text-color: var(--aui-link-hover-color);
        --aui-progress-tracker-visited-step-active-text-color: var(--aui-link-active-color);
        --aui-progress-tracker-future-step-color: @ak-color-N70;
        --aui-progress-tracker-future-step-text-color: @ak-color-N300;

        // Tables
        --aui-table-row-bg-color: transparent;
        --aui-table-row-text-color: @ak-color-N800; // --aui-body-text
        --aui-table-header-bg-color: transparent;
        --aui-table-heading-text-color: @ak-color-N400;
        --aui-table-border-color: @ak-color-N50; // --aui-border
        --aui-table-caption-bg-color: @ak-color-N20;
        --aui-table-caption-text-color: @ak-color-N100;
        --aui-table-list-row-hover-color: @ak-color-N40;
        --aui-table-list-row-subtle-color: @ak-color-N60;
        --aui-table-sortable-hover-bg-color: @ak-color-N30A;
        --aui-table-sortable-active-bg-color: @ak-color-B50;
        --aui-table-sortable-active-border-color: @ak-color-B200;
        --aui-table-sortable-active-text-color: @ak-color-B200;
        --aui-table-sortable-selected-bg-color: transparent;
        --aui-table-sortable-selected-border-color: @ak-color-N400;
        --aui-table-sortable-selected-text-color: @ak-color-N800;

        // Dynamic tables
        --aui-restfultable-row-focused-border-color: @ak-color-N60;
        --aui-restfultable-row-create-border-color: @ak-color-N40;
        --aui-restfultable-row-editable-hover-bg-color: var(--aui-form-field-hover-bg-color);
        --aui-restfultable-error-text-color: var(--aui-form-notification-error-color);
        --aui-restfultable-header-row-bg-color: @ak-color-N0;
        --aui-restfultable-header-row-text-color: @ak-color-N200;
        --aui-restfultable-row-moving-bg-color: @ak-color-N20;
        --aui-restfultable-editable-em-text-color: @ak-color-N80;
        --aui-restfultable-row-active-bg-color: @ak-color-N20;
        --aui-restfultable-row-hover-bg-color: @ak-color-N20;

        // Dropdowns
        --aui-dropdown-bg-color: @ak-color-N50;
        --aui-dropdown-border-color: @ak-color-N70;

        // Selects
        --aui-select2-placeholder-text-color: @ak-color-N100;
        --aui-select2-chosen-bg-color: @ak-color-N30;
        --aui-select2-chosen-text-color: @ak-color-N800;
        --aui-select2-chosen-hover-bg-color: @ak-color-N80;
        --aui-select2-chosen-hover-text-color: @ak-color-N800;
        --aui-select2-active-chosen-bg-color: @ak-color-N80;
        --aui-select2-active-chosen-text-color: @ak-color-N800;
        --aui-select2-field-default-bg-color: var(--aui-form-select-bg-color);
        --aui-select2-field-border-color: var(--aui-form-select-border-color);
        --aui-select2-field-hover-bg-color: var(--aui-form-select-hover-bg-color);
        --aui-select2-drop-bg-color: var(--aui-dropdown-bg-color);

        // Dialogs
        --aui-dialog-bg-color: @ak-color-N40;
        --aui-dialog-border-color: @ak-color-N40;
        --aui-dialog-header-bg-color: var(--aui-dialog-bg-color);
        --aui-dialog-header-warning-bg-color: @ak-color-R200;
        --aui-dialog-header-warning-text-color: @ak-color-N0;
        --aui-dialog-button-hover-border-color: @ak-color-N80;
        --aui-dialog-footer-hint-text-color: @ak-color-N400;
        --aui-inline-dialog-bg-color: var(--aui-dropdown-bg-color); // needs to be slightly lighter than modals so you can see the difference
        --aui-inline-dialog-border-color: var(--aui-dropdown-border-color);

        // Date pickers
        --aui-datepicker-panel-bg-color: @ak-color-N50;
        --aui-datepicker-panel-divider-color: @ak-color-N50;
        --aui-datepicker-heading-bg-color: @ak-color-N50;
        --aui-datepicker-heading-text-color: @ak-color-N600;
        --aui-datepicker-heading-weekdays-text-color: @ak-color-N300;
        --aui-datepicker-option-bg-color: transparent;
        --aui-datepicker-option-text-color: @ak-color-N600;
        --aui-datepicker-option-focus-bg-color: @ak-color-N70;
        --aui-datepicker-option-focus-text-color: @ak-color-N600;
        --aui-datepicker-option-selected-bg-color: @ak-color-N0;
        --aui-datepicker-option-selected-text-color: @ak-color-B200;
        --aui-datepicker-option-unselectable-bg-color: transparent;
        --aui-datepicker-option-unselectable-text-color: @ak-color-N100;
        --aui-datepicker-disabled-text-color: @ak-color-N100;
        --aui-datepicker-hint-text-color: @ak-color-N400;
    }
}
