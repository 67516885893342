.aui-page-sidebar {
  --aui-sidebar-width: 280px;
}
.aui-page-sidebar.aui-sidebar-collapsed {
  --aui-sidebar-width: 56px;
}
.aui-sidebar {
  box-sizing: border-box;
  min-width: 56px;
  position: absolute;
}
.aui-sidebar.aui-sidebar-fly-out {
  --aui-sidebar-width: 280px;
}
.aui-page-sidebar-touch .aui-sidebar.aui-sidebar-fly-out {
  border-right: 1px solid var(--aui-sidebar-bg-color);
  height: 100%;
  position: absolute;
}
.aui-sidebar .aui-sidebar-wrapper {
  box-sizing: border-box;
  border-right: 0;
  border-color: var(--aui-sidebar-bg-color);
  background-color: var(--aui-sidebar-bg-color);
  display: flex;
  flex-direction: column;
  width: var(--aui-sidebar-width);
  position: absolute;
  top: 0;
  bottom: auto;
  z-index: 1;
}
.aui-sidebar .aui-sidebar-wrapper.aui-is-docked {
  position: fixed;
}
.aui-page-sidebar-touch .aui-sidebar {
  background: var(--aui-body-background);
  float: left;
}
.aui-page-sidebar-touch .aui-sidebar .aui-sidebar-wrapper {
  border-right: none;
  position: static;
}
.aui-sidebar .aui-page-header {
  margin: 15px 15px 0 15px;
  padding-bottom: 0;
  position: relative;
}
.aui-sidebar .aui-page-header .aui-page-header-image {
  position: absolute;
}
.aui-sidebar .aui-page-header .aui-avatar {
  display: block;
}
.aui-sidebar .aui-page-header-inner,
.aui-sidebar .aui-page-header-image,
.aui-sidebar .aui-page-header-main {
  display: block;
}
.aui-sidebar .aui-page-header-image + .aui-page-header-main {
  padding-left: 58px;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-image {
  width: 128px;
  margin: 0 auto;
  position: inherit;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-image + .aui-page-header-main {
  padding-top: 10px;
  padding-left: 0;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main {
  text-align: center;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h1,
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.01em;
  text-transform: none;
  padding-top: 10px;
}
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h1:only-child,
.aui-sidebar .aui-sidebar-header-large .aui-page-header-main > h2:only-child {
  padding-top: 0;
}
.aui-sidebar .aui-page-header-main {
  padding-top: 4px;
}
.aui-sidebar .aui-page-header-main > h1,
.aui-sidebar .aui-page-header-main > h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.006em;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
}
.aui-sidebar .aui-page-header-main > h1:only-child,
.aui-sidebar .aui-page-header-main > h2:only-child {
  line-height: 48px;
}
.aui-sidebar .aui-page-header-actions {
  display: none;
}
.aui-sidebar .aui-nav-breadcrumbs > li {
  width: 100%;
}
.aui-sidebar .aui-sidebar-handle {
  background: var(--aui-page-background) url(images/icons/sidebar/icon-handle.png) center center no-repeat;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  width: 10px;
}
.aui-sidebar .aui-sidebar-body {
  box-sizing: content-box;
  flex-grow: 1;
  overflow-y: auto;
}
.aui-sidebar .aui-sidebar-footer {
  box-sizing: border-box;
  border-top: 1px solid var(--aui-sidebar-bg-color);
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  justify-content: space-between;
  min-height: 40px;
  padding: 5px 10px;
}
.aui-page-sidebar-touch .aui-sidebar .aui-sidebar-footer {
  border-bottom: 1px solid var(--aui-sidebar-bg-color);
  margin-top: 10px;
}
.aui-sidebar .aui-sidebar-footer .aui-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.aui-sidebar .aui-sidebar-footer .aui-button ~ .aui-button {
  margin-left: 0;
}
.aui-sidebar .aui-sidebar-footer > .aui-sidebar-toggle {
  order: -1;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-body {
  overflow-x: hidden;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header-inner .aui-page-header-image {
  align-content: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: auto;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header {
  margin-left: 0;
  margin-right: 0;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header .aui-avatar,
.aui-sidebar[aria-expanded="false"] .aui-page-header .aui-avatar .aui-avatar-inner {
  height: 32px;
  width: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header .aui-avatar .aui-avatar-inner > img {
  max-height: 32px;
  max-width: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-page-header-main {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-body {
  box-sizing: border-box;
  bottom: 80px;
  padding: 0;
  width: 100%;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer {
  overflow: hidden;
  flex-direction: column-reverse;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer > .aui-sidebar-toggle > .aui-icon {
  transform: rotate(180deg);
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer > .aui-button {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  text-overflow: clip;
  white-space: normal;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-footer > .aui-button > .aui-button-label {
  display: none;
}
.aui-sidebar.aui-is-animated .aui-sidebar-wrapper {
  transition: width 0.1s ease-in-out;
}
.aui-sidebar.aui-is-animated .aui-sidebar-body {
  transition: padding-top 0.1s ease-in-out;
}
.aui-page-sidebar #content .aui-sidebar ~ [class^="aui-"],
.aui-page-sidebar #content .aui-sidebar + section,
.aui-page-sidebar #content .aui-sidebar + main,
.aui-page-sidebar #content + #footer {
  padding-left: var(--aui-sidebar-width);
}
.aui-page-sidebar.aui-is-animated #content .aui-sidebar ~ [class^="aui-"],
.aui-page-sidebar.aui-is-animated #content .aui-sidebar + section,
.aui-page-sidebar.aui-is-animated #content .aui-sidebar + main,
.aui-page-sidebar.aui-is-animated #content + #footer {
  transition: padding-left 0.1s ease-in-out;
}
.aui-sidebar + .aui-page-panel {
  border-top-width: 0;
  margin-top: 0;
}
.aui-sidebar .aui-navgroup .aui-sidebar-button,
.aui-sidebar-submenu .aui-navgroup .aui-sidebar-button {
  margin-bottom: 10px;
  margin-left: 3px;
}
.aui-sidebar .aui-navgroup .aui-sidebar-button > .aui-icon,
.aui-sidebar-submenu .aui-navgroup .aui-sidebar-button > .aui-icon {
  margin-right: 3px;
}
.aui-sidebar .aui-nav-item-label,
.aui-sidebar-submenu .aui-nav-item-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  word-wrap: normal;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li > .aui-nav-item,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li > .aui-nav-item {
  border-radius: var(--aui-item-border-radius, 3px);
  box-sizing: content-box;
  display: block;
  line-height: 1.42857143;
  padding: 7px 10px;
  padding-left: 7px;
  padding-right: 23px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-item-actions,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav-item-actions {
  top: 9px;
  height: 16px;
  width: 16px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav .aui-nav-item-actions,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav .aui-nav-item-actions {
  margin-right: 0;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav .aui-nav-selected > .aui-nav-item,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav .aui-nav-selected > .aui-nav-item {
  background-color: var(--aui-item-selected-bg);
  color: var(--aui-item-selected-text);
  font-weight: 500;
}
.aui-sidebar .aui-nav > li,
.aui-sidebar-submenu .aui-nav > li {
  position: relative;
}
.aui-sidebar .aui-nav > li > .aui-nav-item,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-icon,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-icon {
  --aui-icon-size: 20px;
  color: var(--aui-sidebar-icon-color);
  margin-right: 10px;
  float: left;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-icon.aui-icon-small::before,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-icon.aui-icon-small::before {
  --aui-icon-size: 16px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle {
  top: 7px;
  left: 7px;
  color: var(--aui-sidebar-toggle-icon-color);
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item {
  padding-left: 37px;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item > .aui-icon,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item > .aui-icon {
  display: none;
}
.aui-sidebar .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle ~ .aui-nav,
.aui-sidebar-submenu .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle ~ .aui-nav {
  padding-left: 30px;
}
.aui-sidebar hr,
.aui-sidebar-submenu hr {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-nav-heading {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item {
  padding-left: 0;
}
.aui-sidebar[aria-expanded="false"] .aui-navgroup-vertical .aui-nav > li[aria-expanded] > .aui-nav-subtree-toggle + .aui-nav-item > .aui-icon {
  display: block;
}
.aui-sidebar[aria-expanded="false"] .aui-nav-sortable > li > a:after {
  left: 0;
  top: 8px;
}
.aui-sidebar[aria-expanded="false"] .aui-nav-item-actions {
  display: none;
}
.aui-sidebar-submenu-dialog > .aui-inline-dialog-contents {
  padding: 10px;
  width: 300px;
  max-height: 812px;
}
.aui-sidebar-submenu {
  position: static;
  background: transparent;
  border-right: 0;
  width: auto;
}
.aui-sidebar .aui-sidebar-group {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.aui-sidebar .aui-sidebar-group .aui-nav-heading {
  padding: 0 5px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group {
  border-radius: var(--aui-item-border-radius, 3px);
  box-sizing: content-box;
  display: block;
  line-height: 1.42857143;
  padding: 7px 10px;
  background-color: var(--aui-item-bg, transparent);
  color: var(--aui-item-text, var(--aui-body-text));
  text-decoration: none;
  background: url(images/icons/sidebar/icon-group.svg) 50% center no-repeat;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group:focus,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group:hover {
  background-color: var(--aui-item-focus-bg);
  color: var(--aui-item-focus-text, inherit);
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group.active {
  background-color: var(--aui-item-selected-bg);
  color: var(--aui-item-selected-text);
  font-weight: 500;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group > .aui-nav {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group.aui-sidebar-group-actions,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group.aui-sidebar-group-tier-one {
  background: none;
  border-radius: 0;
  cursor: auto;
  padding: 0;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions {
  margin: 10px 0 15px 0;
  height: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions::after {
  background: url(images/icons/sidebar/icon-group-actions.svg) no-repeat center center;
  background-size: 20px;
  border: 1px solid transparent;
  border-radius: 50%;
  content: "";
  display: block;
  height: 32px;
  left: 0;
  margin-left: 11px;
  margin-right: 11px;
  position: absolute;
  top: 0;
  width: 32px;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions:hover::after,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions:focus::after,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-actions.active::after {
  background-color: var(--aui-button-default-active-bg-color);
  border-color: var(--aui-button-default-active-bg-color);
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one {
  background: none;
  height: auto;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one:after {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one:focus,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one:hover {
  background-color: transparent;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav {
  display: block;
  height: auto;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li {
  display: block;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item > .aui-icon {
  float: none;
  margin: 0 auto;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item.active {
  background-color: var(--aui-item-selected-bg);
  color: var(--aui-item-selected-text);
  font-weight: 500;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li .aui-nav-item-label {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-subtree-toggle {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li .aui-nav-item-actions {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav .aui-nav {
  display: none;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group ~ .aui-sidebar-group {
  border-top: 2px solid var(--aui-border);
  margin-top: 15px;
  padding-top: 10px;
}
.aui-sidebar .aui-nav > li > .aui-nav-item > .aui-badge,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > .aui-badge,
.aui-sidebar .aui-nav > li > .aui-nav-item > aui-badge,
.aui-sidebar-submenu .aui-nav > li > .aui-nav-item > aui-badge {
  background-color: var(--aui-sidebar-badge-bg-color);
  border-color: var(--aui-sidebar-bg-color);
  color: var(--aui-sidebar-badge-text-color);
  float: right;
  margin-top: 2px;
}
.aui-sidebar[aria-expanded="false"] .aui-nav > li > a:hover > .aui-nav-item > .aui-badge {
  visibility: visible;
}
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item > .aui-badge,
.aui-sidebar[aria-expanded="false"] .aui-sidebar-group-tier-one .aui-nav > li > .aui-nav-item > aui-badge {
  border-width: 2px;
  border-style: solid;
  font-size: 9px;
  margin-top: 0;
  padding: 0.2em 0.4em;
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  z-index: 1;
}
