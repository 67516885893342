@import (reference) './imports/global';

#aui-flag-container {
    @gutter: @aui-grid * 3;
    pointer-events: none; // so that users can click through the margin of the flag.
    position: fixed;
    top: @gutter + 55px; // height of header plus some fudge.
    right: @gutter;
    z-index: @aui-z-flag-container;
}

.aui-flag {
    // should always be displayed, even when not "open", so it can be animated
    display: block;
    left: 0;
    max-height: 300px; //We need to set an explicit value to be able to animate the property
    opacity: 0;
    position: relative;
    top: -10px;
    width: 400px;
    #aui.transition(opacity @aui-flag-fade-in-time);
    #aui.transition(top @aui-flag-slide-in-time);

    &:not([open]) {
        left: 300px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        top: 0;
        // these animations are tied to "not open" so that they only run
        // when the flag disappears, but not when it appears initially.
        #aui.transition(max-height @aui-flag-stack-shrink-time @aui-flag-stack-shrink-delay);
        #aui.transition(margin-bottom @aui-flag-stack-shrink-time @aui-flag-stack-shrink-delay);
        #aui.transition(opacity @aui-flag-fade-out-time);
        #aui.transition(left @aui-flag-slide-out-time);
    }

    &[open] {
        display: block;
        margin-bottom: @aui-flag-padding; // it's on the bottom so there's less clunky animation when flags in a stack disappear
        opacity: 1;
        top: 0;
        left: 0;
    }

    .aui-message {
        --aui-message-icolor: var(--aui-flag-info-color);
        --aui-message-padding: @aui-flag-padding;

        background-color: var(--aui-flag-bg-color);
        color: inherit; // this avoids text colours being inherited for what are meant to contrast with solid blocks of colour in both light and dark modes

        #aui.shadow.z500();
        pointer-events: auto; // to allow normal interaction with messages inside flags
        border-width: 0;

        &.aui-message-warning {
            --aui-message-icolor: var(--aui-flag-warning-color);
        }

        &.aui-message-error {
            --aui-message-icolor: var(--aui-flag-error-color);
        }

        &.aui-message-success {
            --aui-message-icolor: var(--aui-flag-success-color);
        }
    }
}
