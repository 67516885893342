@import (reference) './imports/aui-theme/components/spinner';

@keyframes spinnerRotateAnimation {
    to {
        transform: rotate(360deg);
    }
}

aui-spinner {
    @size: var(--aui-spinner-size);
    --aui-spinner-size: @aui-spinner-size-medium;
    display: block;
    position: relative;
    color: var(--aui-spinner-color);
    width: @size;
    height: @size;

    &[size="small"] {
        --aui-spinner-size: @aui-spinner-size-small;
    }

    &[size="large"] {
        --aui-spinner-size: @aui-spinner-size-large;
    }

    .aui-spinner {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;

        & > svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite spinnerRotateAnimation;
            fill: none;
            stroke: currentColor;
            stroke-dasharray: calc(@size * 2.8);
            stroke-dashoffset: calc(@size * 2.25);
            stroke-linecap: round;
            stroke-width: calc(@size / 10);
            transform-origin: center;
        }
    }

    // deprecated; do not use.
    &[filled] {
        // The "filled" variant of the spinner should always take up no space in the DOM
        height: 0 !important;
        width: 0 !important;
        margin: auto;

        > .aui-spinner > svg {
            left: calc(var(--aui-spinner-size) / 2 * -1);
        }
    }
}
