@import (reference) './imports/global';

aui-message {
    display: block;
}

.aui-message {
    --aui-message-icolor: var(--aui-message-info-icon-color);
    --aui-message-ig: @aui-glyph-info-circle;
    --aui-message-isize: @aui-icon-size-small;
    --aui-message-padding: 15px;
    --aui-message-gutter: calc(var(--aui-message-padding) * 2 + 10px);

    // Make the message iconic ;)
    #aui-message.icon-bar(var(--aui-message-ig), var(--aui-message-icolor, inherit));

    // The message's base CSS rules
    & {
        background: var(--aui-message-info-bg-color);
        border-radius: @aui-message-border-radius;
        color: var(--aui-message-info-text-color, var(--aui-body-text));
        line-height: @aui-message-line-height;
        overflow-wrap: break-word;
        padding: var(--aui-message-padding);
        padding-left: var(--aui-message-gutter);
        position: relative;
        word-wrap: break-word;
        word-break: normal;
    }

    &:first-child {
        margin-top: 0;
    }

    &.closeable {
        padding-right: var(--aui-message-gutter);

        .aui-close-button {
            position: absolute;
            right: var(--aui-message-padding);
            top: var(--aui-message-padding);
        }
    }
}

aui-message, .aui-message {
    margin: (@aui-grid*2) 0 0 0;

    &:first-child {
        margin-top: 0;
    }
}

.aui-message-error {
    --aui-message-icolor: var(--aui-message-error-icon-color);
    --aui-message-ig: @aui-glyph-exclamation-circle;
    background-color: var(--aui-message-error-bg-color);
    color: var(--aui-message-error-text-color);
}

.aui-message-warning {
    --aui-message-icolor: var(--aui-message-warning-icon-color);
    --aui-message-ig: @aui-glyph-exclamation-triangle;
    background-color: var(--aui-message-warning-bg-color);
    color: var(--aui-message-warning-text-color);
}

.aui-message-success,
.aui-message-confirmation {
    --aui-message-icolor: var(--aui-message-success-icon-color);
    --aui-message-ig: @aui-glyph-check-circle;
    background-color: var(--aui-message-success-bg-color);
    color: var(--aui-message-success-text-color);
}

.aui-message-change {
    --aui-message-icolor: var(--aui-message-change-icon-color);
    --aui-message-ig: @aui-glyph-question-circle;
    background-color: var(--aui-message-change-bg-color);
    color: var(--aui-message-change-text-color);
}

/* Message titles */
.aui-message p.title {
    font-weight: bold;
}

/* IE double bolds otherwise... */
.aui-message p.title strong {
    font-weight: inherit;
}

// Footer interop (provisional, just enough to stop them looking broken)
#footer .aui-message {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
}

// Better interop of links and focus styles
// for non-flag messages
:not(.aui-flag) > .aui-message {
    // AUI-5192: currentColor is quoted to work around Edge 18 bug.
    --aui-link-color: 'currentColor';
    --aui-link-hover-color: 'currentColor';
    --aui-link-active-color: 'currentColor';
    --aui-link-decoration: underline;
    --aui-link-hover-decoration: double underline;
    --aui-focus: currentcolor;
}
